import React from 'react';
import {graphql} from 'gatsby';

import ArticleContent from '../components/ArticleContent';
import IssueContent from '../components/IssueContent';
import Layout from '../components/Layout';

const Issue = ({
    data,
    pageContext: {articles, description, from, keywords, lang, title, url},
}) => (
    <Layout
        description={description}
        keywords={keywords}
        lang={lang}
        name='issue'
        path={from}
        title={title}
        url={url}
    >
        <IssueContent content={data.markdownRemark} lang={lang} />
        {articles.map(article =>
            <ArticleContent key={article.fields.slug} article={article} lang={lang} slug={article.fields.slug} />
        )}
    </Layout>
);

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: {slug: {eq: $slug}}) {
            frontmatter {
                abstracts
                editors {
                    de
                    en
                    pl
                }
                issue
                pdf
                book
                title
                year
            }
            html
        }
    }
`;

export default Issue;
